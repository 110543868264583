import { FaMap } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { GiRotaryPhone } from "react-icons/gi";
import { FaMailBulk } from "react-icons/fa";
import React, {useEffect, useState} from 'react'
import AxiosHelper from "../../services/AxiosHelper";

const ContactBox = () => {
    const api = AxiosHelper();
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        api.get('?table_name=contacts').then((res) => {
            setContacts(res[0]);
        })
    }, [])

    return (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <li
                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-zinc-800 text-center shadow"
            >
                <div className="flex flex-1 flex-col items-center p-8">
                    <FaMap size={50} className="text-primary" />
                    <dl className="mt-4 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-white font-medium">{contacts && contacts.address}</dd>
                    </dl>
                </div>
            </li>
            <li
                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-zinc-800 text-center shadow"
            >
                <div className="flex flex-1 flex-col items-center p-8">
                    <FaPhone size={50} className="text-primary" />
                    <dl className="mt-4 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-white font-medium">{contacts.phone1 + ' - ' + contacts.phone2}</dd>
                    </dl>
                </div>
            </li>
            <li
                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-zinc-800 text-center shadow"
            >
                <div className="flex flex-1 flex-col items-center p-8">
                    <GiRotaryPhone size={50} className="text-primary" />
                    <dl className="mt-4 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-white font-medium">{contacts.phone3}</dd>
                    </dl>
                </div>
            </li>
            <li
                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-zinc-800 text-center shadow"
            >
                <div className="flex flex-1 flex-col items-center p-8">
                    <FaMailBulk size={50} className="text-primary" />
                    <dl className="mt-4 flex flex-grow flex-col justify-between">
                        <dd className="text-sm text-white font-medium">{contacts.email}</dd>
                    </dl>
                </div>
            </li>
        </ul>
    )
}

export default ContactBox
