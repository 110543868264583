import axios from 'axios';

const AxiosHelper = () => {

    const instance = axios.create({
        baseURL: 'https://paylasimgida.com/admin/api.php',
        timeout: 10000,
    });

    const get = async (url) => {
        // startLoading();
        try {
            console.log(url);
            const response = await instance.get(url);
            return response.data;
        } catch (error) {
            console.log(error)
            
            throw error;
        } finally {
            // stopLoading();
        }
    };

    const post = async (url, data) => {
        // startLoading();
        try {
            const response = await instance.post(url, data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            // setNotificationContent({ 'header': 'Hata', 'content': error.message, type: 'error' })
            throw error;
        } finally {
            // stopLoading();
        }
    };

    const del = async (url) => {
        // startLoading();
        try {
            const response = await instance.delete(url);
            return response.data;
        } catch (error) {
            // setNotificationContent({ 'header': 'Hata', 'content': error.message, type: 'error' })
            throw error;
        } finally {
            // stopLoading();
        }
    };

    const update = async (url, data) => {
        // startLoading();
        try {
            const response = await instance.put(url, data);
            return response.data;
        } catch (error) {
            throw error;
        } finally {
            // stopLoading();
        }
    };

    return {
        get,
        post,
        del,
        update,
        instance
    };
}

export default AxiosHelper;