import React from 'react'
import { HomeSlider, InfoOneCard, WeBrandsLogo } from '../component/ComponentExport'
import { Helmet } from 'react-helmet'
import ProductsImg from '../assets/img/webrands.jpg'

const WeBrands = () => {
    return (
        <div>
            <Helmet>
                <title>Paylaşım Pastacılık - Markalarımız</title>
                <meta name="description" content="Paylaşım Pastacılık - Markalarımız" />
                {/* <meta name="keywords" content={blog.keywords} /> */}
            </Helmet>
            <img className="w-full" src={ProductsImg} />

            <div className="container mx-auto flex flex-col relative gap-6 px-2 lg:px-4 sm:mt-8">
                <InfoOneCard />
                <span className="font-medium text-white text-xl">Çalıştığımız Markalar</span>
                <WeBrandsLogo />
            </div>
        </div>
    )
}

export default WeBrands