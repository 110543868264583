import React, { useEffect, useState } from 'react'
import AxiosHelper from '../../services/AxiosHelper'

const WeBrandsLogo = () => {
  const api = AxiosHelper();
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    api.get('?table_name=collaborations').then((res) => {
      setBrands(res);
    })
  }, [])
  return (
    <div className="grid grid-cols-1 gap-4 overflow-hidden sm:mx-0 md:grid-cols-3">
      {brands.map((val, key) => (
        <div className="relative group h-max bg-white rounded-[20px] sm:p-16 p-12 hover:cursor-pointer">
          <img
            className="h-32 w-full object-contain transition-opacity duration-300 ease-in-out group-hover:blur-lg"
            src={`https://paylasimgida.com/admin//${val.logo}`}
            alt="Transistor"
            width={158}
            height={48}
          />
          <div className="absolute inset-0 m-auto flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
            <img
              className="max-h-12 w-full object-contain transition-opacity duration-300 ease-in-out"
              src={`https://paylasimgida.com/admin//${val.logo}`}
              alt="Transistor"
              width={158}
              height={48}
            />
            <p className="text-center font-medium p-4">
              {val.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WeBrandsLogo