import React from 'react'
import { ContactBox, HomeSlider, InfoOneCard } from '../component/ComponentExport'
import GoogleMapReact from 'google-map-react';
import { Helmet } from 'react-helmet';
import ProductsImg from '../assets/img/contact-slide.jpg'

const Contact = () => {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <div>
            <Helmet>
                <title>Paylaşım Pastacılık - İletişim</title>
                <meta name="description" content="Paylaşım Pastacılık - İletişim" />
                {/* <meta name="keywords" content={blog.keywords} /> */}
            </Helmet>
            {/* <HomeSlider /> */}
            <img className="w-full" src={ProductsImg} />

            <div className="container mx-auto flex flex-col relative gap-6 px-2 lg:px-4 sm:mt-8">
                <InfoOneCard />
                <div>
                    <ContactBox />
                </div>
                <div style={{ height: '70vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                    </GoogleMapReact>
                </div>

                <div className=" grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                        <div className="mt-2">
                            <input
                                type="text"
                                placeholder={"Adınız Soyadınız"}
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="bg-zinc-800 border border-white/30 block w-full rounded-[12px] py-1.5 text-primary shadow-sm placeholder:text-primary font-medium sm:text-sm sm:leading-6 indent-4"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <div className="mt-2">
                            <input
                                type="text"
                                placeholder="Telefon"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="bg-zinc-800 border border-white/30 block w-full rounded-[12px] py-1.5 text-primary shadow-sm placeholder:text-primary font-medium sm:text-sm sm:leading-6 indent-4"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <div className="mt-2">
                            <input
                                placeholder="E-Posta"
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="bg-zinc-800 border border-white/30 block w-full rounded-[12px] py-1.5 text-primary shadow-sm placeholder:text-primary font-medium sm:text-sm sm:leading-6 indent-4"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <div className="mt-2">
                            <textarea
                                placeholder="Mesajınız"
                                cols={40}
                                rows={10}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="bg-zinc-800 border border-white/30 block w-full rounded-[12px] py-1.5 text-primary shadow-sm placeholder:text-primary font-medium sm:text-sm sm:leading-6 indent-4"
                            />
                        </div>
                    </div>
                    <button className="bg-primary p-3 rounded-[20px] text-white font-medium">Gönder</button>
                </div>
            </div>
        </div>
    )
}

export default Contact