import React, { useEffect, useState } from 'react'
import { BsCake } from "react-icons/bs";
import Services1 from '../../assets/img/services1.png'
import Services2 from '../../assets/img/services2.png'
import Services3 from '../../assets/img/services3.png'
import Services4 from '../../assets/img/services4.png'
import Services5 from '../../assets/img/services5.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import AxiosHelper from '../../services/AxiosHelper';
import { Link } from 'react-router-dom';

const Services = () => {
    // const services = [
    //     { title: "PASTACILIK", icon: <BsCake />, image: Services1 },
    //     { title: "YAĞ GRUBU", icon: <BsCake />, image: Services2 },
    //     { title: "UN & NİŞASTA", icon: <BsCake />, image: Services3 },
    //     { title: "SÜT & GIDA", icon: <BsCake />, image: Services4 },
    //     { title: "AMBALAJ", icon: <BsCake />, image: Services5 },
    // ]

    const api = AxiosHelper();
    const [service, setService] = useState([]);

    useEffect(() => {
        api.get('?table_name=services').then((res) => {
            setService(res);
        });
    }, []);

    return (
        <div className="flex">
            <Swiper
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 30
                    },
                }}
            >
                {service && service.map((val, key) => {
                    return (
                        <SwiperSlide>
                            <Link to={`/hizmet/${val.slug}`}>
                                <div className="relative group hover:cursor-pointer">
                                    <img src={`https://paylasimgida.com/admin//${val.image}`} alt={val.title} className="rounded-[20px] h-auto w-auto" />
                                    <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-max">
                                        <div
                                            className="
                                    bg-black 
                                    border-primary 
                                    text-primary
                                    p-3
                                    flex
                                    items-center
                                    rounded-[15px]
                                    border-[1px]
                                    group-hover:bg-transparent
                                    backdrop-blur-lg
                                    duration-300
                                    ">
                                            <div className='mr-2'>
                                                <img style={{filter: "invert(53%) sepia(87%) saturate(1762%) hue-rotate(351deg) brightness(99%) contrast(95%)"}}
                                                src={`https://paylasimgida.com/admin//${val.icon}`} className="rounded-[20px] " />
                                            </div>
                                            <span className="font-medium">{val.title}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Services