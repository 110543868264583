import React from 'react'

const InfoOneCard = () => {
    return (
        <div className="grid grid-cols-4 gap-4">
            <div className="col-span-1 max-sm:col-span-4"><img className="rounded-[45px] max-sm:w-[150px] max-sm:hidden" src="/static/media/image1.dfa60d438ee6fbb8a7ef.png" alt="Image 1" /></div>
            <div className="col-span-3 max-sm:col-span-4 sm:flex bg-white shadow-2xl rounded-[20px] justify-center items-center p-4">
                <div className="flex sm:flex-col flex-row justify-between items-center sm:h-full px-8">
                    <div className="sm:h-12 sm:w-[4px] h-[1.5px] w-32 bg-primary"></div>
                    <div className="rotating-image-container">
                        <img className="w-[200px] max-sm:w-[100px] rotating-image" src="/static/media/logoNoBg.dc657b495cdf37d42895.png" alt="Logo" />
                    </div>
                    <div className="sm:h-12 sm:w-[4px] h-[1.5px] w-32 bg-primary"></div>
                </div>
                <div className="text-gray-500">
                    <h1 className="font-medium text-lg">PAYLAŞIM PASTACILIK</h1><br />
                    <p className="">Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.<br /><br />Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve lojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz. Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz</p>
                </div>
            </div>
        </div>)
}

export default InfoOneCard