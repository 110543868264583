import React, { useEffect, useState } from 'react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Flour from '../../assets/img/flour.png';
import AxiosHelper from '../../services/AxiosHelper';
const HomeSlider = ({ flour }) => {
  const api = AxiosHelper();
  const [images, setImages] = useState([]);
  useEffect(() => {
    api.get('?table_name=slider').then((res) => {
      setImages(res);
    })
  }, []);
  return (
    <div className="relative">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        // className="rounded-[20px]"
      >
        {images && (
          images.map((data, key) => {
            return (
              <SwiperSlide key={key} className="rounded-[20px]">
                <img
                className="w-full"
                  src={`https://paylasimgida.com/admin//${data.image}`}
                />
              </SwiperSlide>
            )
          })
        )}
      </Swiper>
      {/* {flour && <img className="absolute bottom-0 w-full z-10" src={Flour} />} */}
    </div>
  )
}

export default HomeSlider