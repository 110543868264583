import React, { useState, useEffect } from 'react'
import { LogoNoBg } from '../ComponentExport'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import AxiosHelper from '../../services/AxiosHelper';

const CollaborationLogos = ({ hideMyLogo }) => {
    const api = AxiosHelper();

    const [logos, setLogos] = useState();
    useEffect(() => {
        api.get('?table_name=collaborations').then((res) => {
            setLogos(res);
        })
    }, [])
    return (
        <div className="mx-auto sm: relative flex justify-center w-full">
            <div className={`${hideMyLogo ? "bg-zinc-800" : "bg-primary"} py-6 w-full p-3  ${hideMyLogo ? "rounded-[20px]" : "rounded-t-[20px]"} z-20 mx-auto`}>
                <Swiper
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    loop={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 30
                        },
                    }}
                >
                    {logos && logos.map((val, key) => {
                        return(
                            <SwiperSlide className="items-center" key={key}>
                                {console.log(val)}
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                    src={`https://paylasimgida.com/admin//${val.logo}`} alt="Transistor"
                                    width={158}
                                    height={48}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            {!hideMyLogo && <div className="text-gray-900 bg-primary absolute max-sm:hidden rounded-[20px] z-10 w-56 h-44 -translate-x-1/2 -translate-y-1/2 right-1/2 left-1/2 top-1/2 bottom-1/2 flex items-end justify-center">
                <div className="absolute -top-9 -translate-x-1/2 -translate-y-1/2 right-1/2 left-1/2 h-20 w-28">
                    <LogoNoBg className="bg-white rounded-full p-2 border w-52 border-primary" />
                </div>
                <div className="text-white font-medium py-4">ÇALIŞTIĞIMIZ MARKALAR</div>
            </div>}
        </div>
    )
}

export default CollaborationLogos