import React, { useEffect, useState } from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, down } from "react-icons/fa";
import { Logo } from '../ComponentExport';
import { Link } from 'react-router-dom';
import AxiosHelper from '../../services/AxiosHelper';

const Footer = () => {
  const api = AxiosHelper();
  const [footer, setFooter] = useState([]);
  useEffect(() => {
    api.get('?table_name=contacts').then((res) => {
      setFooter(res[0]);
    })
  }, [])
  const navigation = {
    sitemap: [
      { name: 'ANASAYFA', to: '/' },
      { name: 'KURUMSAL', to: '/kurumsal' },
      { name: 'ÜRÜNLERİMİZ', to: '/urunlerimiz' },
      { name: 'MARKALARIMIZ', to: '/markalarimiz' },
      { name: 'MEDYA', to: '/medya' },
      // { name: 'İLETİŞİM', to: '/iletisim' },
    ],
    contact: [
      { name: 'Telefon: +90 242 346 33 30', href: '#' },
      { name: 'Telefon: +90 532 054 84 48', href: '#' },
      { name: 'E-Posta: info@paylasimgida.com', href: '#' },
    ]
  }
  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="space-y-8 col-span-1">
            <Link to="/">
              <Logo className="h-24 w-auto" />
            </Link>
          </div>
          <div className="col-span-4">
            <div className="mt-16 grid grid-cols-2 max-sm:grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8 max-sm:flex max-sm:justify-between">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">SİTE HARİTASI</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.sitemap.map((item) => (
                      <li key={item.name}>
                        <Link to={item.to} className="text-sm leading-6 text-white hover:text-primary">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">İLETİŞİM</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    <li>
                      <a href={`tel:${footer.phone1}`} className="text-sm leading-6 text-white hover:text-primary">
                        Telefon: {footer.phone1}
                      </a>
                    </li>
                    <li>
                      <a href={`tel:${footer.phone2}`} className="text-sm leading-6 text-white hover:text-primary">
                        Telefon: {footer.phone2}
                      </a>
                    </li>
                    <li>
                      <a href={`email:${footer.email}`} className="text-sm leading-6 text-white hover:text-primary">
                        E-Posta: {footer.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="md:grid md:grid-cols-2 md:gap-8"> */}
              <div className="">
                <div className="flex gap-4 justify-center w-full items-center">
                  {/* <span className="text-white font-medium">Sosyal Medya</span> */}
                  <a href={footer.facebook} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaFacebookF /></a>
                  <a href={footer.twitter} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaTwitter /></a>
                  <a href={footer.instagram} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaInstagram /></a>
                </div>
                <div className="max-sm:mt-2 mt-5">
                  <div class="bg-primary max-sm:bg-transparent gap-2 py-4 px-2 rounded-[46px] justify-center items-center flex flex-col xl:flex-row max-sm:flex-col">
                    <span class="text-white font-medium text-xs">E-BÜLTEN ABONELİĞİNE ÜYE OL</span>
                    <div class="h-full flex">
                      <input class="rounded-l-[46px] indent-4 h-12" type="text" placeholder="E-Posta" />
                      <button class="bg-black max-sm:bg-primary h-12 rounded-r-[46px] text-white px-2 font-medium">Gönder</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">&copy; ByDesign BECREATIVE</p>
        </div>
      </div>
    </footer>
  )
}


export default Footer