import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import Image1 from '../../assets/img/image1.png'
import Image2 from '../../assets/img/image2.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css';
import AxiosHelper from '../../services/AxiosHelper'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductTab() {

    const [categories, setCategories] = useState();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeTabIndex2, setActiveTabIndex2] = useState(0);

    const api = AxiosHelper();
    useEffect(() => {
        api.get('?endpoint=products').then((res) => {
            console.log(res)
            setCategories(res)
        })
    }, [])

    return (
        <div className="w-full px-2 py-4 sm:px-0">
            <Tab.Group onChange={index => setActiveTabIndex(index)}>
                <Tab.List className="rounded-xl my-3">
                    <Swiper
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 3000 }}
                        breakpoints={{
                            320: { slidesPerView: 2.2, spaceBetween:10 },
                            768: { slidesPerView: 2.2, spaceBetween:10 },
                            1024: { slidesPerView: 10, spaceBetween:15 }
                        }}
                    >
                        {categories && categories.map((category, index) => (
                            <SwiperSlide className="!w-auto" key={category.id}>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'flex w-max items-center gap-3 rounded-lg p-2 text-sm font-medium',
                                            'focus:outline-none',
                                            selected ? 'bg-primary text-white shadow' : 'border border-primary text-white'
                                        )
                                    }
                                >
                                    <img
                                        src={`https://paylasimgida.com/admin/pages/${category.icon}`}
                                        alt={category.title}
                                        className={`w-7 ${index === activeTabIndex ? 'filter-white' : ''}`}
                                    />
                                    <span>
                                        {category.name}
                                    </span>
                                </Tab>

                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {categories && categories.map((category, idx) => (
                        <Tab.Panel key={idx}>
                            <Tab.Group onChange={index => setActiveTabIndex2(index)}>
                                <Tab.List>
                                    <Swiper
                                        className="flex gap-3 justify-between"
                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 3000 }}
                                        breakpoints={{
                                            320: { slidesPerView: 2.2, spaceBetween: 10 },
                                            768: { slidesPerView: 2.2, spaceBetween: 10 },
                                            1024: { slidesPerView: 8, spaceBetween: 10 }
                                        }}
                                    >
                                        {category.alt_tabs.map((post, postIdx) => (
                                            <SwiperSlide key={postIdx} className="w-full max-w-max mr-4">
                                                <Tab
                                                    className={({ selected }) =>
                                                        classNames(
                                                            ' max-sm:px-2 flex items-center gap-4 w-max rounded-lg p-2.5 text-sm font-medium leading-5',
                                                            'focus:outline-none',
                                                            selected ? 'bg-primary text-white shadow' : 'border border-primary text-white'
                                                        )
                                                    }
                                                >
                                                    {/* <img src={`https://paylasimgida.com/admin/pages//${post.icon}`} alt={post.title} className="w-20" /> */}
                                                    {post.title}
                                                </Tab>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Tab.List>
                                <Tab.Panels className="mt-2">
                                    {categories && category.alt_tabs.map((post, postIdx) => (
                                        <Tab.Panel key={postIdx}>
                                            <div className="sm:grid grid-cols-7">
                                                <div className='bg-zinc-800 flex flex-col gap-4 text-primary rounded-xl p-3 mr-6 max-sm:mr-0 col-span-2'>
                                                    <h3 className="font-medium text-xl leading-5">
                                                        {post.title}
                                                    </h3>
                                                   {post.content && <div dangerouslySetInnerHTML={{ __html: post.content.replace(/\n/g, '<br>') }} />}
                                                </div>
                                                {post.image && (
                                                    <img src={`https://paylasimgida.com/admin//${post.image}`} alt={post.title} className="mt-3 col-span-5" />
                                                )}
                                            </div>
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
