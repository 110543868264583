import React, { useEffect, useState } from 'react'
import { CollaborationLogos, HomeSlider, InfoOneCard, Logo, LogoNoBg, PromotionBox, Services } from '../component/ComponentExport';
import AxiosHelper from '../services/AxiosHelper';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

const ServiceDetail = () => {

  const api = AxiosHelper();
  const params = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    api.get('?service_detail=' + params.slug).then((res) => {
      setBlog(res[0]);
    })
  }, []);

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content="Gym Factory" />
        <meta name="keywords" content={blog.keywords} />
      </Helmet>
    <div className="sm:mt-32 mt-16">
    <div className="container mx-auto flex flex-col relative gap-6 max-sm:px-4">
        <div className="">
          <div className="mx-auto">
            {blog && <div className='w-full px-3 max-sm:px-0 mt-12'>
              <div className="mx-auto max-w-2xl text-center mb-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">{blog.title}</h2>
              </div>
              <div className='sm:flex gap-4'>
                <div className='mb-10 flex justify-center items-center'>
                  {/* <img src={`https://paylasimgida.com/admin//${blog.image}`} alt='Resim' /> */}
                </div>
                <div className='text-grey' dangerouslySetInnerHTML={{ __html: blog.content }} />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ServiceDetail