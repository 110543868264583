import React, { useState, useEffect } from 'react'
import { CollaborationLogos, HomeSlider, InfoOneCard, Logo, LogoNoBg, PromotionBox, Services } from '../component/ComponentExport';
import { Link } from 'react-router-dom';
import AxiosHelper from '../services/AxiosHelper';
import { Helmet } from 'react-helmet';
import ProductsImg from '../assets/img/media.jpg'

const Media = () => {
  const api = AxiosHelper();

  const [blog, setBlog] = useState([]);

  useEffect(() => {
    api.get('?table_name=blog_posts').then((res) => {
      setBlog(res);
    })
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>Paylaşım Pastacılık - Medya</title>
        <meta name="description" content="Paylaşım Pastacılık - Medya" />
        {/* <meta name="keywords" content={blog.keywords} /> */}
      </Helmet>
      {/* <HomeSlider flour /> */}
      <img className="w-full" src={ProductsImg} />

      <div className="container mx-auto flex flex-col relative gap-6 px-2 lg:px-4 sm:mt-8">
        {/* BLOG */}
        <div className="">
          <div className="mx-auto max-w-7xl">
            <div className="mx-auto max-w-2xl mt-3 text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">Blog / Haber</h2>
            </div>
            <div className="mx-auto mt-16 max-sm:mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {blog && blog.map((post, key) => (
                <Link to={`/blog/${post.slug}`}>
                  <article key={key} className="flex flex-col items-start justify-between">
                    <div className="relative w-full">
                      <img
                        src={`https://paylasimgida.com/admin//${post.cover_photo}`}
                        alt=""
                        className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                      />
                      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="max-w-xl">
                      <div className="mt-8 flex items-center gap-x-4 text-xs">
                        <time dateTime={post.datetime} className="text-gray-400">
                          {post.date}
                        </time>
                        {/* <a
                      href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </a> */}
                      </div>
                      <div className="group relative">
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-200 group-hover:text-gray-600">
                          <a href={post.href}>
                            <span className="absolute inset-0" />
                            {post.title}
                          </a>
                        </h3>
                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-300">{post.description}</p>
                      </div>
                    </div>
                  </article>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Media