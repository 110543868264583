import React from 'react'
import { FaPlay } from "react-icons/fa";
import Flour from '../../assets/img/flour.png'
import TanitimBg from '../../assets/img/tanitim-bg.png'

const PromotionBox = () => {
    return (
        <div className="relative">
            <img src={TanitimBg} />
            <button className="absolute top-1/2 max-sm:top-[15%] left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-col items-center">
                <div className="border-2 border-primary shadow-lg shadow-primary bg-primary/50 text-black/50 rounded-full p-4 w-fit">
                    <FaPlay size={25} />
                </div>
                <span className="text-white font-medium mt-2">TANITIM FİLMİ</span>
            </button>
            <div className="sm:absolute top-1/2 z-20 left-1/4 transform sm:-translate-y-1/2 sm:-translate-x-1/2 max-sm:translate-x-0">
                <div className="sm:w-[320px] backdrop-blur-3xl bg-primary sm:bg-inherit sm:rounded-[20px] max-sm:rounded-b-[20px] p-2">
                    <h2 className="sm:text-primary text-white font-medium">PAYLAŞIM PASTACILIK</h2>
                    <div className="text-white">
                        Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.
                        <br /><br />
                        Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve
                        lojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.
                        Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz.
                        <br /><br />
                        Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve
                        lojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.
                        Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                    </div>
                </div>
            </div>
            <img className="absolute bottom-0 w-full z-10 hidden sm:block" src={Flour} />
        </div>
    )
}

export default PromotionBox