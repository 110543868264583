import React from 'react'
import { CollaborationLogos, HomeSlider, InfoOneCard, ProductTab, PromotionBox } from '../component/ComponentExport'
import { Helmet } from 'react-helmet'
import ProductsImg from '../assets/img/products-slide.jpeg'

const Products = () => {
    return (
        <div>
            <Helmet>
                <title>Paylaşım Pastacılık - Ürünler</title>
                <meta name="description" content="Paylaşım Pastacılık - Ürünler" />
                {/* <meta name="keywords" content={blog.keywords} /> */}
            </Helmet>
            <img className="w-full" src={ProductsImg} />

            {/* <HomeSlider /> */}
            <div className="container mx-auto flex flex-col relative gap-6 px-2 lg:px-4 sm:mt-8">
                <div className=''>
                    <InfoOneCard />
                </div>
                <ProductTab />
                <div className="mt-8 sm:mt-24">
                    <CollaborationLogos />
                    <PromotionBox />
                </div>
            </div>
        </div>
    )
}

export default Products