import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { HomePageLayout } from './component/ComponentExport';
import {Home, ErrorPage, Corpate, Products, WeBrands, Contact, Media, ServiceDetail, BlogDetail} from './pages/PagesExport';
import './index.css'

export default function App() {
  return (
    <BrowserRouter>
      <main className='w-full'>
        <Routes>
          <Route element={<HomePageLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/kurumsal" element={<Corpate/>}/>
            <Route path="/urunlerimiz" element={<Products/>}/>
            <Route path="/markalarimiz" element={<WeBrands/>}/>
            <Route path="/iletisim" element={<Contact/>}/>
            <Route path="/medya" element={<Media/>}/>

            
            <Route path="/hizmet/:slug" element={<ServiceDetail />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />

            {/* <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/sss" element={<SSS />} />
            <Route path="/hizmet/:slug" element={<ServicesDetail />} />
            <Route path="/kulupler" element={<Clubs />} />
            <Route path="/studyo-ders-saatleri" element={<Studio />} />
            <Route path="/insan-kaynaklari" element={<IK />} />
            <Route path="/galeri" element={<Gallery />} />
            <Route path="/iletisim" element={<Contact />} /> */}
          </Route>
          {/* 404 */}
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </main>
    </BrowserRouter >
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
