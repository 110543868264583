import React, { Fragment, useEffect, useState } from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, down } from "react-icons/fa";
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Logo } from '../ComponentExport';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { MdCancel } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import CatalogImg from '../../assets/img/catalog.png'
import { BsWhatsapp } from 'react-icons/bs';
import AxiosHelper from '../../services/AxiosHelper';
const Header = () => {
  const api = AxiosHelper();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation();
  const disableKatalog = ["/", "/kurumsal", "/urunlerimiz", "/markalarimiz", "/medya", "/iletisim"];
  const [corpateData, setCorpateData] = useState([]);
  useEffect(() => {
    api.get('?table_name=contacts').then((res) => {
      setCorpateData(res[0]);
    })
  }, [])
  const menuItems = [
    { to: '/', title: 'ANASAYFA' },
    { to: '/kurumsal', title: 'KURUMSAL' },
    { to: '/urunlerimiz', title: 'ÜRÜNLERİMİZ' },
    { to: '/markalarimiz', title: 'MARKALARIMIZ' },
    { to: '/medya', title: 'MEDYA' },
    { to: '/iletisim', title: 'İLETİŞİM' },
  ];

  return (
    <header className="absolute z-10 top-10 sm:top-20 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
      {console.log(location.pathname, " asdsad")}
      {disableKatalog.includes(location.pathname) && <div class="sm:flex hidden justify-center absolute w-full h-screen -z-10">
        <div className={`relative m-[72px] w-11/12 ${location.pathname == "/" ? "home-line" : "other-line"} rounded-3xl border-2`}>
          <a href='#' class="bg-white hover:scale-105 duration-300 rounded-full absolute -left-11 top-1/2 transform -translate-y-1/2 p-2">
            <div className="relative p-8">
              <div className="absolute left-0 right-0 top-0 bottom-0 gap-1 flex flex-col items-center justify-center">
                <img src={CatalogImg} />
                <span class="text-primary font-bold text-xs ">KATALOG</span>
              </div>
            </div>
          </a>
          <a href="#" class="bg-green-700 hover:scale-105 duration-300 rounded-full absolute -right-8 top-1/2 transform -translate-y-1/2 p-4">
            <BsWhatsapp size={30} className='text-white' />
          </a>
        </div>
      </div>}

      {/* <header className="container mx-auto flex flex-col relative gap-6 px-6 lg:px-8 gap-6 px-6 lg:px-8 mt-4"> */}
      <nav className="w-full flex items-center justify-around sm:p-6 p-0" aria-label="Global">
        <Link to="/">
          <Logo className="w-14 sm:w-24" />
        </Link>
        <div className="flex lg:hidden">
          <button
            onClick={() => setMobileMenuOpen(true)}
            type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Open main menu</span>
            <GiHamburgerMenu className="text-white" size={30} />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 bg-primary p-4 rounded-[46px]">
          {menuItems.map((item, index) => (
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 hover:text-black duration-300 ${isActive ? 'text-gray-900' : 'text-white'}`}

            >
              {item.title}
            </NavLink>
          ))}
        </div>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end w-max"> */}
        <div className="hidden lg:flex  w-max">
          <a href={corpateData?.facebook} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaFacebookF /></a>
          <a href={corpateData?.twitter} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaTwitter /></a>
          <a href={corpateData?.instagram} target='_blank' className='bg-primary text-white p-3 mx-1 rounded-full'><FaInstagram /></a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Paylaşım Pastacılık</span>
              <Link to="/">
                <Logo className="w-14 sm:w-24" />
              </Link>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <MdCancel size={35} />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {menuItems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.to}
                    className={({ isActive }) =>
                      `-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${isActive ? 'text-primary' : 'text-gray-900'} hover:bg-gray-50`}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.title}
                  </NavLink>

                ))}
                <NavLink
                  className={({ isActive }) =>
                    `-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  KATALOG
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    `-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center gap-2`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <BsWhatsapp className='text-green-700' size={20} />
                  WHATSAPP
                </NavLink>
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 flex block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  <a href="#" className='bg-primary text-white p-3 mx-1 rounded-full'><FaFacebookF /></a>
                  <a href="#" className='bg-primary text-white p-3 mx-1 rounded-full'><FaTwitter /></a>
                  <a href="#" className='bg-primary text-white p-3 mx-1 rounded-full'><FaInstagram /></a>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>

  )
}

export default Header