import React from 'react'
import { BlackLogo, CollaborationLogos, HomeSlider, Logo, Services } from '../component/ComponentExport'
import { FaPlay } from "react-icons/fa";
import NoLogo from '../assets/img/logoNo.png'
import { Helmet } from 'react-helmet';
import CorpateImg from '../assets/img/corpate.jpeg'
const Corpate = () => {
    return (
        <div>
            <Helmet>
                <title>Paylaşım Pastacılık - Kurumsal</title>
                <meta name="description" content="Paylaşım Pastacılık - Kurumsal" />
                {/* <meta name="keywords" content={blog.keywords} /> */}
            </Helmet>
            {/* <HomeSlider /> */}
            <img className="w-full" src={CorpateImg} />
            <div className="container mx-auto flex flex-col relative gap-6 px-2 lg:px-4 sm:mt-8">
                <div className="block sm:grid grid-cols-6 items-center">
                    <div className="col-span-1">
                        <div className="flex my-4 flex-col justify-between items-center h-full px-8 relative">
                            <div className="max-sm:hidden h-12 w-[4px] bg-primary"></div>
                            <img className="sm:500px w-[200px] max-sm:w-[150px] rotating-image" src={NoLogo} alt="Logo" />
                            <div className="absolute inset-0 flex justify-center items-center">
                                <button className="flex flex-col items-center">
                                    <div className="border-2 border-primary shadow-lg shadow-primary bg-primary/50 text-black/50 rounded-full p-4 w-fit">
                                        <FaPlay size={25} />
                                    </div>
                                    <span className="text-white font-medium max-sm:text-sm mt-2">TANITIM FİLMİ</span>
                                </button>
                            </div>
                            <div className="max-sm:hidden h-12 w-[4px] bg-primary"></div>
                        </div>
                    </div>
                    <div className="col-span-5">
                        <h1 className="text-white text-3xl mb-6">PAYLAŞIM PASTACILIK</h1>
                        <p className="text-white">
                            Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve ojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.
                            Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                            <br /><br />
                            Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve ojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.
                            Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                            <br /><br />
                            Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve ojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.
                            Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                        </p>
                    </div>
                </div>

                <div className="block sm:flex items-center justify-between relative mt-20 bg-zinc-800 p-4 rounded-[20px]">
                    <div className="text-white">
                        <h2 className="text-2xl">MİSYON</h2>
                        <p className='text-sm'>
                            Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve ojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                        </p>
                    </div>
                    <div className="mx-36" />
                    <div className="w-full sm:absolute relative inset-0 flex justify-center items-center">
                        <BlackLogo />
                    </div>
                    <div className="text-white">
                        <h2 className="text-2xl">MİSYON</h2>
                        <p className='text-sm'>
                            Antalya Paylaşım Gıda, kaliteli ürün stoğu ve marka bileşenleri ile hizmet vermektedir.Ev dışı tüketim ürünlerinde, özellikle hamur işleri ve pastacılık grubunda ürün gamımız, hızlı tedarik ve ojistik sistemimiz ile pazarlama, satış ve dağıtım alanında faaliyet göstermekteyiz.Web sitemiz üzerinden bağlantılı bulunduğumuz, bayisi olduğumuz markaları inceleyebilirsiniz
                        </p>
                    </div>
                </div>
                <div className="my-28">
                    <Services />
                </div>
                <CollaborationLogos hideMyLogo />
            </div>
        </div>
    )
}

export default Corpate